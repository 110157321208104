<template>
	<div class="outer-wrapper filters"> 

		<div id="top-nav"> 
			<router-link :to="{ name: 'Tours Results' }">
				<img src="@/assets/close-icon.png" alt="close" class="close-icon"/>
			</router-link>
		</div>
	
		<div class="inner-wrapper main"> <!-- Inner wrapper handles global padding -->
			<!-- {{ searchFilters }} -->

			<router-link class="filter-option" :to="{ name: 'Tours Filters Price' }">
				<div class="aligner"></div>
				<img class="icon" width="19px" src="@/assets/filters/price.png" />
				<div class="main-text">
					<div class="title">Price</div>
					<div class="sub" v-if="searchFilters.priceRange.low && searchFilters.priceRange.high">
						{{ formatPrice(searchFilters.priceRange.low) }} - {{ formatPrice(searchFilters.priceRange.high) }}
					</div>
					<div class="sub" v-else>
						Any
					</div>
				</div>
				<img class="arrow-right" width="24px" src="@/assets/filters/arrow-right.png" />
			</router-link>

			<router-link class="filter-option" :to="{ name: 'Tours Filters Duration' }">
				<div class="aligner"></div>
				<img class="icon" width="24px" src="@/assets/filters/duration.png" />
				<div class="main-text">
					<div class="title">Duration</div>

					<div v-if="searchFilters.duration.low && searchFilters.duration.high">
						<div class="sub" v-if="searchFilters.duration.low === searchFilters.duration.high">
							<span v-if="searchFilters.duration.low === 1">{{ searchFilters.duration.low }} day</span>
							<span v-else>{{ searchFilters.duration.low }} days</span>
						</div>
						<div class="sub" v-else>
							<span v-if="searchFilters.duration.low === 1">Min. {{ searchFilters.duration.low }} day</span>
							<span v-else>Min. {{ searchFilters.duration.low }} days</span>

							<span v-if="searchFilters.duration.high === 1"> - {{ searchFilters.duration.high }} day</span>
							<span v-else> - {{ searchFilters.duration.high }} days</span>
						</div>
					</div>
					<div class="sub" v-else>
						Any
					</div>
				</div>
				<img class="arrow-right" width="24px" src="@/assets/filters/arrow-right.png" />
			</router-link>

			<router-link class="filter-option" :to="{ name: 'Tours Filters Locations' }">
				<div class="aligner"></div>
				<img class="icon" width="19px" src="@/assets/filters/location.png" />
				<div class="main-text">
					<div class="title">Locations</div>
					<div class="sub">
						
					</div>
				</div>
				<img class="arrow-right" width="24px" src="@/assets/filters/arrow-right.png" />
			</router-link>
		</div>

		<div class="bottom inner-wrapper">
			<router-link :to="{ name: 'Tours Results' }">
				<button class="button">View Results</button>
			</router-link>
		</div>
			
	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import Toggle from '@/components/Toggle.vue';
import helpers from '@/helpers/helpers.js';
import router from '@/router';

export default {
    data () {
        return {
           
        }
	},
	components: {
		Toggle,
	},
   computed: {
		...mapState({
			searchFilters: state => state.toursSearch.searchFilters,
			searchValues: state => state.toursSearch.searchValues,
			searchResults: state => state.toursSearch.searchResults,
		}),
		...mapGetters({
			filterOptions: 'toursSearch/getFilterOptions',
		})
	},
    methods: {
		formatPrice: (price) => helpers.formatPrice("GBP", price, 1, 0),
	},
	created () {
		if(this.searchResults.length == 0) { // Probably a page refresh - redirect to results page (with query params)
			router.push({
				name: 'Tours Results',
			})
		}
	},
};
</script>


<style scoped>
.close-icon {
	right: initial;
	left: 23px;
}
.match-origin {
	font-size: 12px;
	margin-bottom: 20px;
}
.toggle {
	float: right;
}

.filter-option {
	display: block;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
	padding: 15px 22px;
	height: 80px;
	box-sizing: border-box;
	vertical-align: center;
	position: relative;
	margin-bottom: 14px;
	color: initial !important;
}
.filter-option:visited {
	color: initial;
}
.filter-option .aligner {
	height: 100%;
	vertical-align: middle;
	width: 1px;
	display: inline-block;
}
.filter-option .icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: 22px;
}
.filter-option .main-text {
	vertical-align: middle;
	display: inline-block;
	max-width: calc(100% - 55px);
}
.filter-option .arrow-right {
	display: inline-block;
	vertical-align: middle;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}

.cross .opt {
	text-decoration: line-through;
}


</style>